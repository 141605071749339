<template>
  <div class="relative paged-list">
    <div>
      <!-- Fixes error when quickly navigating backwards twice -->
      <NuxtErrorBoundary>
        <Swiper
          :modules="modules"
          :speed="500"
          :autoplay="{
            delay: 6000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }"
          :effect="'fade'"
          :fadeEffect="{ crossFade: true }"
          :autoHeight="true"
          :navigation="{
            nextEl: sliderNext,
            prevEl: sliderPrev,
          }"
          :grid="{
            rows: mobileRows,
          }"
          :pagination="{
            el: sliderPagination,
            clickable: true,
            type: 'fraction',
            hideOnClick: false,
          }"
          :slidesPerView="'auto'"
          :spaceBetween="spaceBetween"
          :initialSlide="initialSlide"
          :grabCursor="false"
          :watchOverflow="true"
          :observer="true"
          :observeParents="true"
          :mousewheel="false"
          :breakpoints="{
            640: {
              grid: {
                rows: 1,
              },
              spaceBetween: spaceBetween / 2,
            },
          }"
          @slideChange="emit('slideChange', $event)"
        >
          <slot />
        </Swiper>
      </NuxtErrorBoundary>
    </div>
    <div class="relative h-6 opacity-50" :class="controlsMargin">
      <div
        ref="sliderNext"
        slot="button-next"
        class="swiper-button-next select-none"
      >
        <IconForward />
      </div>
      <div
        ref="sliderPagination"
        class="swiper-pagination leading-6 text-sm font-sans"
      />
      <div
        ref="sliderPrev"
        slot="button-prev"
        class="swiper-button-prev select-none"
      >
        <IconBackward />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper } from 'swiper/vue'
import type ExtendedSwiper from '~/typesManual/swiper'
import {
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  Pagination,
} from 'swiper'
import 'swiper/css/bundle'

import IconForward from '~/assets/icons/forward.svg?component'
import IconBackward from '~/assets/icons/backward.svg?component'

withDefaults(
  defineProps<{
    spaceBetween?: number
    mobileRows?: number
    initialSlide?: number
    controlsMargin?: string
  }>(),
  {
    spaceBetween: 30,
    mobileRows: 1,
    initialSlide: 0,
    controlsMargin: 'mt-2.5',
  }
)

const emit = defineEmits<{
  slideChange: [swiper: ExtendedSwiper]
}>()

const modules = [
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  FreeMode,
  Grid,
  Pagination,
]

const sliderNext = ref<HTMLDivElement | null>(null)
const sliderPrev = ref<HTMLDivElement | null>(null)
const sliderPagination = ref<HTMLDivElement | null>(null)
</script>

<style lang="scss" scoped>
.paged-list {
  @apply overflow-hidden -mx-4 px-4;

  @screen md {
    @apply overflow-visible mx-0 px-0;
  }
}
.swiper-button-next {
  right: 0;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next,
.swiper-button-prev {
  z-index: 15;
  bottom: 0;
  top: auto;

  img {
    width: 24px;
    height: 24px;
  }
  &:after {
    display: none;
  }

  &.swiper-button-disabled {
    opacity: 0;
  }
}
</style>
